import './App.css';
import IntroPage from './IntroPage';

function App() {
  return (
    <div className="App"> 
      <IntroPage />
    </div>
  );
}
export default App;